// components/AddressAutocomplete.js
import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import cookie from 'js-cookie';
import axios from 'axios';
import { useRouter } from 'next/router';
import { savePincode } from '../../api.services.js/others';

const AddressAutocomplete = (props) => {
  const router = useRouter()
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [getLocationLoading, setgetLocationLoading] = useState(false);
  const [error, seterror] = useState(false);



  const handleSelect = (address) => {
    setSelectedAddress(address);

    // Retrieve additional details including pincode
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId: address.value.place_id }, async (results, status) => {
      if (status === 'OK' && results[0]) {
        const pincode = extractPincodeFromResults(results[0]);
        if(!pincode && results[0].place_id){
          let pinnn = await axios.get(`https://proxy.orientbell.com/getpininfobyplaceid?pin=${results[0].place_id}`)
          const { lat, lng } = pinnn.data.pplaceInfo.result.geometry.location;
          const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);
          const address = response.data.address;
          if (address && address.postcode) {
            const pincode = address.postcode;
            props.setLocationPopop(false)
            props.setleadpopop()
            localStorage.setItem("pincodeSkip", true);
            cookie.set('pincode', pincode, { expires: 7 ,secure: true, sameSite: 'Strict' });
        savePincode({pincodedetails:pincode})

            setgetLocationLoading(false)
            if (router.asPath.includes('/tiles')) {
              router.reload();
            } 
            }
            return
        }
        // onSelect({ ...address, pincode });
        localStorage.setItem("pincodeSkip", true, { expires: 7 ,secure: true, sameSite: 'Strict' });
        cookie.set('pincode', pincode);
        savePincode({pincodedetails:pincode})
        props.setLocationPopop(false)
        props.setleadpopop()
        setgetLocationLoading(false)
        if (router.asPath.includes('/tiles')) {
          router.reload();
        } 
      } else {
        console.error('Error fetching geocode data:', status);
      }
    });
  };
  const handlePincodeFetch = async () => {
    setgetLocationLoading(true)
    try {
      if ('geolocation' in navigator) {
        const permission = await navigator.permissions.query({ name: 'geolocation' });
  
        if (permission.state === 'granted') {
          if (!cookie.get('pincode')) {
            await retrieveGeolocation();
          }
        } else if (permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              if (!cookie.get('pincode')) {
                await retrieveGeolocation();
              }
            },
            (error) => {
              setgetLocationLoading(false)
              seterror('Please enter your address / pin code for more accurate Information.')
              console.error('Error retrieving location:', error);
              // Handle error if needed
            }
          );
        } else {
          // Permission denied or unavailable
          setgetLocationLoading(false)
          seterror('Please enter your address / pin code for more accurate Information.')
          console.log('Geolocation permission denied or unavailable.');
          // Handle denied permission or unavailable state
        }
      } else {
        setgetLocationLoading(false)
        seterror('Geolocation is not supported by this browser.')
        console.log('Geolocation is not supported by this browser.');
        // Handle unsupported geolocation
      }
    } catch (error) {
      setgetLocationLoading(false)
      seterror('Error checking geolocation permission:')

      console.error('Error checking geolocation permission:', error);
      // Handle error if needed
    }
  };
  const retrieveGeolocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          // timeout: 5000, // Set a timeout for geolocation retrieval (5 seconds)
        });
      });
      if (!router.asPath.includes('/tiles')) {
        props.setLocationPopop(false)
        props.setleadpopop()
        setgetLocationLoading(false)
      }
      const { latitude, longitude } = position.coords;
  
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
      const address = response.data.address;
  
      if (address && address.postcode) {
        const pincode = address.postcode;
        props.setLocationPopop(false)
        props.setleadpopop()
        localStorage.setItem("pincodeSkip", true);
        cookie.set('pincode', pincode, { expires: 7 ,secure: true, sameSite: 'Strict' });
        savePincode({pincodedetails:pincode})
        setgetLocationLoading(false)
        if (router.asPath.includes('/tiles')) {
          router.reload();
        } 
        }
    } catch (error) {
      console.error('Error retrieving location:', error);
  
      const errorMessage = error.message || 'Something went wrong while retrieving your location.';
      swal(errorMessage);
    }
  };
  const extractPincodeFromResults = (result) => {
    const postalCodeComponent = result.address_components.find(
      (component) => component.types.includes('postal_code')
    );

    return postalCodeComponent ? postalCodeComponent.short_name : '';
  };

  const renderSuggestions = (active, suggestions, onSelectSuggestion) => {
    const filteredSuggestions = suggestions.filter((suggestion) => {
      const hasPostalCode = suggestion.terms.some(term => term.types.includes('postal_code'));
      return hasPostalCode;
    });
  
    return filteredSuggestions.map((suggestion) => {
      const isActive = active === suggestion.active;
      const classNames = `suggestion-item ${isActive ? 'active' : ''}`;
  
      return (
        <div
          key={suggestion.placeId}
          className={classNames}
          onClick={() => onSelectSuggestion(suggestion)}
        >
          {suggestion.description}
        </div>
      );
    });
  };
  
  

  return (
    <>
    <button type="button" className={getLocationLoading ? "btn btn-secondary" : "btn btn-success"} disabled={getLocationLoading} onClick={()=>{
          handlePincodeFetch()
    }}>{getLocationLoading ? 'Fetching' : "Detect Location"}</button>
    <div class="location-or-bx"><p>OR</p></div>
    <GooglePlacesAutocomplete
      apiKey="AIzaSyBrmlHMs5JUON_ErXHEKk_HSd2UoLN20HQ"
      autocompletionRequest={{
        componentRestrictions: {
          country: ['in'],
        },
      }}
      selectProps={{
        value: selectedAddress,
        onChange: handleSelect,
        placeholder: 'Search for your address',
      }}
      renderSuggestions={renderSuggestions}
    />
    {error && <p className='error text-danger'>{error}</p>}
    </>
  );
};

export default AddressAutocomplete;


